<template>
    <div class="products">
        <Breadcrumb v-bind:item="title"/>
        <div v-if="isFetching" class="lds-ring">
            <img class="loading-gif" src="@/assets/img/loadingwheel.gif" alt="">
        </div>
        <div v-else class="products-grid-wrap">
            <SectionHeader v-bind:title="title"/>

            <input v-on:input="search($event.target.value)" type="text" name="search" id="search" placeholder="SEARCH">
            <img class="icon-search" src="../assets/img/icon_search.svg">

            <div class="lds-ring" v-if="$apollo.loading">
                <img class="loading-gif" src="@/assets/img/loadingwheel.gif" alt="">
            </div>
            <div v-else class="products-loaded">
                <div v-if="!isSearching" class="products-grid">
                    <div class="product" v-for="(product, item) in this.products" v-bind:key="item">
                        <div v-if="product.bottleImage" class="item">
                            <img @click="goToProduct(product.slug, product.productId, product.producer.exhibitorId)" :src="product.bottleImage.fluid.src" alt="product">

                            <h2  @click="goToProduct(product.slug, product.productId, product.producer.exhibitorId)" class="product-title">{{product.shortName}}</h2>
                            <p v-if="product.description"  class="product-desc">{{shorten(product.description, 130, true)}}</p>
                        </div>
                    </div>
                </div>

                <div v-else class="products-grid">
                    <div class="product" v-for="(product, item) in this.results" v-bind:key="item">
                        <div v-if="product.bottleImage" class="item">
                            <img @click="goToProduct(product.slug, product.productId, product.producer.exhibitorId)" :src="product.bottleImage.fluid.src" alt="product">

                            <h2  @click="goToProduct(product.slug, product.productId, product.producer.exhibitorId)" class="product-title">{{product.shortName}}</h2>
                            <p v-if="product.description" class="product-desc">{{shorten(product.description, 130, true)}}</p>
                        </div>
                    </div>
                </div>

                <div v-if="!allLoaded && !isSearching" class="load-more">
                    <p @click="loadMore()">LOAD MORE</p>
                </div>

                <div v-if="!allLoadedSearch && isSearching" class="load-more">
                    <p @click="loadMoreSearch()">LOAD MORE</p>
                </div>

                <div v-if="noResults && allLoadedSearch" class="no-results">
                    <p>NO RESULTS FOUND</p>
                </div>

                <div class="pagination">
                    <a href="#" class="previous">previous</a>
                    <a href="#">&#60;&#60;</a>
                    <a href="#">&#60;</a>
                    <a class="active" href="#">1</a>
                    <a href="#">2</a>
                    <a href="#">3</a>
                    <a href="#">4</a>
                    <a href="#">5</a>
                    <a href="#">6</a>
                    <a href="#">&#62;</a>
                    <a href="#">&#62;&#62;</a>
                    <a href="#" class="next">next</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from '@/store/index.js'

import Breadcrumb from '@/components/snippets/Breadcrumb.vue'
import SectionHeader from '@/components/snippets/SectionHeader.vue'

import gql from 'graphql-tag'
import Fuse from 'fuse.js'

// const increment = 12

export default {
    name: 'Products',

    components: { 
        SectionHeader,
        Breadcrumb
    },

    data() {
        return {
            title: 'Wines',
            wineListId: String,
            itemsPerPage: 12,
            itemsPerPageSearch: 12,
            isFetching: true,
            isSearching: false,
            fetched: false,
            searched: false,
            allLoaded: false,
            allLoadedSearch: false,
            noResults: false,
            products: [],
            productsAll: [],
            results: [],
            resultsAll: []
        }
    },

    beforeMount() {
        this.wineListId = store.getters.getwineListId;
    },

    methods: {
        goToProduct(slug, productId, exhibitorId) {
            this.$router.push({name:'product', params:{slug:slug, productId:productId, exhibitorId:exhibitorId}})
        },
        shorten(str, n, useWordBoundary) {
            if (str.length <= n) { return str; }
            const subString = str.substr(0, n-1);
            return (useWordBoundary 
                ? subString.substr(0, subString.lastIndexOf(" ")) 
                : subString) + "..."
        },
        loadMore() {
            this.itemsPerPage += 12
            this.products = this.productsAll.slice(0, this.itemsPerPage)

            if (this.itemsPerPage >= this.productsAll.length) {
                this.allLoaded = true;
            } 
        },
        loadMoreSearch() {
            this.itemsPerPageSearch += 12
            this.results = this.resultsAll.slice(0, this.itemsPerPageSearch)

            if (this.itemsPerPageSearch >= this.resultsAll.length) {
                this.allLoadedSearch = true;
            }
        },
        search(string) {
            console.log("Searching");

            this.allLoadedSearch = false;
            this.$data.noResults = false

            if (string == "") {
                this.isSearching = false
                return false
            }

            var options;

            if (!isNaN(string)) {
                options = {
                    includeScore: true,
                    threshold: 0.0,
                    keys: [
                        'vintage'
                    ]
                }
            } else {
                options = {
                    includeScore: true,
                    threshold: 0.3,
                    keys: [
                        'shortName', 
                        'vintage',
                        'designation',
                        'wineColor',
                        'wineType',
                        'producer.name',
                        'grapeVarieties.varietyName',
                        {
                            name: 'country',
                            weight: 0.5
                        },
                        {
                            name: 'region',
                            weight: 0.5
                        },
                        {
                            name: 'subregion',
                            weight: 0.5
                        },
                        {
                            name: 'description',
                            weight: 0.2
                        }
                    ]
                }
            }

            this.isSearching = true


            const fuse = new Fuse(this.$data.productsAll, options)
            const result = fuse.search(string)

            this.$data.results = []
            this.$data.resultsAll = []

            for (let i = 0; i < result.length; i++) {
                const obj = result[i];

                if (obj.item.bottleImage !== null) {
                    this.$data.resultsAll.push(obj.item)
                }
            }

            if (this.$data.resultsAll.length == 0) {
                this.$data.noResults = true
                this.$data.allLoadedSearch = true
            }

            if (this.$data.itemsPerPageSearch >= this.$data.resultsAll.length) {
                this.$data.allLoadedSearch = true;
            }

            this.$data.results = this.$data.resultsAll.splice(0, 12)
        
        }
    },

    apollo: {

        event: {
            query: gql`query ($eventId: ID!) {
                event(eventId: $eventId) {
                    products {
                        nodes {
                            bottleImage {
                                fluid(maxWidth: 420, maxHeight:560, crop: PAD){
                                    src
                                }
                            }
                            name
                            shortName
                            slug
                            productId
                            producer {
                                name
                                exhibitorId
                            }

                            ... on Wine {
                                alcohol
                                country
                                region
                                subregion
                                otherAwards
                                description
                                wineColor
                                wineType
                                designation
                                vintage
                                
                                tastingNotes {
                                    note
                                }

                                grapeVarieties {
                                    varietyName
                                }

                                awards {
                                    award
                                    medal
                                }
                            }
                        }
                    }
                }
            }`,

            skip() {
                return this.fetched;
            },

            variables() {
                return {
                    itemsPerPage: this.itemsPerPage,
                    eventId: this.wineListId
                }
            },

            result ({ data, loading }) {

                if (!loading) {
                    var productsArray = data.event.products.nodes
                    productsArray = productsArray.filter(function( item ) {
                        let img = item.bottleImage;
                        
                        if (img !== null) {
                            return true
                        }
                    });

                    this.productsAll = productsArray
                    this.products = productsArray.slice(0,12)
                    this.isFetching = false
                    this.fetched = true
                }
            },
        }
    },
}
</script>